import { defaultLanguage } from '@ruokaboksi/api-client';
import type { I18nOptions } from 'vue-i18n';
import cs from './locales/cs.json';
import en from './locales/en.json';
import et from './locales/et.json';
import fi from './locales/fi.json';

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: defaultLanguage,
  globalInjection: true,
  messages: { fi, en, cs, et },
})) as I18nOptions;
